/* eslint-disable @typescript-eslint/no-var-requires */
import * as React from 'react'
import styled from 'styled-components'
import { Link, PageProps } from 'gatsby'

import MainLayout from 'components/layouts/Main'
import Container from 'components/common/Container'
import Paragraph from 'components/common/typography/Paragraph'
import CaretRight from 'components/vectors/CaretRight'

import colors from 'lib/utils/colors'

import appContent from 'content/app.json'
import materialsContent from 'content/materials/index.json'
import withAuth from 'lib/utils/withAuth'

const ContentContainer = styled(Container)`
  text-align: left;
  flex-direction: column;
  justify-content: initial;
  align-items: initial;
  padding: 70px 12px 0 12px;

  & > :not(:last-child) {
    margin-bottom: 12px;
  }
`

const ListItemContainer = styled(Container)`
  background-color: ${colors.White};
  border-radius: 24px;
  padding: 17px 15px 22px 21px;
`

const ListIconContainer = styled(Container)`
  display: block;
  width: 124px;
  margin-right: 5px;
`

const ListItemTextContainer = styled(Container)`
  flex-direction: column;
  margin-right: 22px;
`

const Label = styled.h5`
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: ${colors.Black};
  margin-bottom: 7px;
  white-space: pre-wrap;
`

const IconContainer = styled(Container)`
  margin: auto;
`

type ListItem = {
  imageIcon: string
  title: string
  description: string
  linkTo: string
}

const MaterialsPage: React.FC<PageProps> = ({ location }) => {
  const { title, content } = materialsContent

  return (
    <MainLayout
      backgroundColor={appContent.backgroundColor}
      backgroundImage={appContent.backgroundImage}
      showTabBar={!!appContent.tabBar}
      currentPath={location.pathname}
      pageTitle={title.text}
      content={
        <ContentContainer>
          {content.items.map((item: ListItem, key: string) => {
            return (
              <Link to={item.linkTo} key={key} style={{ textDecoration: 'none' }}>
                <ListItemContainer>
                  <ListIconContainer>
                    {React.createElement(require(`assets/icons/${item.imageIcon}`))}
                  </ListIconContainer>
                  <ListItemTextContainer>
                    <Label>{item.title}</Label>
                    <Paragraph
                      text={item.description}
                      style={{ color: colors.Black, marginBottom: 0 }}
                    />
                  </ListItemTextContainer>
                  <IconContainer>
                    <CaretRight />
                  </IconContainer>
                </ListItemContainer>
              </Link>
            )
          })}
        </ContentContainer>
      }
    />
  )
}

export default withAuth(MaterialsPage)
